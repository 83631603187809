.cross {
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  position: relative;
  transition: 0.2s;

  &:active {
    opacity: 0.8;
  }

  svg {
    position: relative;
    z-index: 1;
  }

  &:hover {
    &:after {
      transform: scale(1.08);
    }
  }
}

.openedMenu {
  z-index: 4;
  top: 19px;
  right: 17px;
  transform: scale(0.6);
}
