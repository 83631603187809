@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  gap: 24px;
  padding: 16px 0;

  @include media-breakpoint-down(s) {
    gap: 16px;
    padding: 0;
  }
}

.image {
  position: relative;
  width: 64px;
  height: 64px;

  @include media-breakpoint-down(s) {
    width: 32px;
    height: 32px;
  }
}

.subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px
}
