@use "styles/mixins/breakpoints" as *;

.wrapper {
  position: relative;
  width: 100%;
  background: #FAFAFF;
  padding: 80px 0 40px;

  @include media-breakpoint-down(s) {
    padding: 40px 0;
  }
}

.container {
  --grid-gap: 0;
}

.title {
  display: block;
  color: var(--gray-palettes-100);
  padding-bottom: 40px;

  @include media-breakpoint-down(s) {
    padding-bottom: 24px;
  }
}

.faqWrapper {
  display: flex;
  gap: 16px;
  padding: 40px 0;

  @include media-breakpoint-down(s) {
    grid-row: 2;
    align-items: center;
    flex-direction: column;
    padding: 24px 0;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--blue-palettes-90);
  height: 46px;
  transition: 0.2s;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.link {
  white-space: nowrap;
  color: var(--blue-palettes-90);
  margin: 0;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}
