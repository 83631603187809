@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: none;

  :global {
    body {
      overflow: hidden !important;
    }
  }

  @include media-breakpoint-down(m) {
    display: block;
  }
}

.background {
  background: rgba(9, 30, 56, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  animation: fade_in_menu;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  overflow-y: scroll;
  list-style-type: none;
  padding: 0;

  &::-webkit-scrollbar {
    width: 2px;
  }
}

.fadeMenu {
  background: white;
  position: fixed;
  width: 100%;
  opacity: 1;
  height: 60%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  animation: fade_in_menu;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  overflow-y: scroll;
  list-style-type: none;
  padding: 0;

  &::-webkit-scrollbar {
    width: 2px;
  }
}

@keyframes fade_in_menu {
  from {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

.header {
  display: flex;
  align-items: center;
  margin: 40px auto 0;
  padding-inline: 40px;
}

.logo {
  cursor: pointer;
  transition: .2s;

  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: 1;
  }
}

.content {
  color: black;
  padding: 40px;
  display: grid;
  gap: 48px;
}


.wrapperMenu {
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  cursor: pointer;
  padding: 0;
}

.item {
  cursor: pointer;
  transition: 0.2s;
  padding: 8px 0;
  color: var(--dark-blue-palettes-100);

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

