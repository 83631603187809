@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: none;

  @include media-breakpoint-down(s) {
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    grid-row-start: 8;
  }

  img {
    z-index: 1;
  }
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;

  img {
    z-index: 1;
  }
}

.img {
  width: 165px;
  height: 55px;
  transition: .2s;

  @include media-breakpoint-down(xs) {
    width: 140px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.icons {
  display: flex;
  gap: 8px;
}

.subtitle2 {
  display: block;
  text-align: center;
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 31px;
}