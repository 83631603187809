@use "styles/mixins/breakpoints" as *;

.wrapper {
  position: absolute;
  width: 100%;
  max-width: 1216px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px auto 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 1;

  @include media-breakpoint-down(s) {
    margin: 20px auto 0;
    padding-inline: 24px;
  }
}

