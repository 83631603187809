@use "styles/mixins/breakpoints" as *;

.dropdownContent {
  --typography-subtitle2-color: var(--dark-blue-palettes-100);
  --dropdown-content-background-color: #f2f6fa;

  margin: 0 20px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  text-align: center;
  width: 330px;
  background-color: var(--dropdown-content-background-color);
  border-radius: 4px;

  @include media-breakpoint-down(xs) {
    width: auto;
  }
}

.button {
  --button-font-size-small: 14px;
  --button-border-radius: 4px;
  --button-height-small: 34px;
}
