@use "styles/mixins/breakpoints" as *;

.wrapperBg {
  width: 100%;
  background-color: var(--dark-blue-palettes-100);
}

.container {
  position: relative;
  padding-top: 34px;
  padding-bottom: 34px;
  row-gap: 0;

  @include media-breakpoint-down(m) {
    overflow: hidden;
  }

  @include media-breakpoint-down(s) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.head {
  position: relative;
  white-space: normal;
  color: var(--white-palettes-100);
  z-index: 2;

  @include media-breakpoint-down(s) {
    --typography-h2-font-size: 32px;
  }
}

.mosPayments {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  max-width: 278px;
  background: var(--red-palettes-90);

  &Wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 16px;
    z-index: 4;

    @include media-breakpoint-down(m) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;
      gap: 8px;
    }
  }
}

.subtitleApple {
  display: flex;
  gap: 4px;
  margin-top: 32px;
  color: var(--black-60);
  white-space: nowrap;

  @include media-breakpoint-down(s) {
    display: none;
  }

  &Mobile {
    display: none;

    @include media-breakpoint-down(s) {
      display: flex;
      gap: 4px;
      margin-top: 32px;
      color: var(--black-60);
      white-space: nowrap;
    }

    @include media-breakpoint-down(xs) {
      white-space: normal;
    }
  }
}

.imageQr {

  span {
    display: flex !important;
  }

  @include media-breakpoint-down(s) {
    display: none;
  }
}

.subtitleQR {
  color: var(--white-palettes-100);
}

.wrapperQr {
  display: flex;
  gap: 24px;
  margin-top: 8px;

  @include media-breakpoint-down(s) {
    margin-top: 32px;
  }
}

.wrapperLogoAppleGoogle {
  display: flex;
  gap: 8px;
  margin-top: 32px;

  @include media-breakpoint-down(s) {
    margin-top: 32px;
    grid-row-start: 4;
  }
}

.wrapperLogoStore {
  display: none;


  @include media-breakpoint-down(s) {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }
}

.imageRing {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  width: 471px;
  height: 420px;
}

.imagePhone {
  grid-row-start: 1;
  grid-row-end: 7;
  margin-left: 0 !important;

  @include media-breakpoint-down(m) {
    grid-row-start: 6;
    grid-row-end: 10;
  }

  @include media-breakpoint-down(s) {
    width: 270px;
    height: 270px;
    margin-top: 20px;
  }
}

.imageNotice {
  grid-row-start: 3;
  grid-row-end: 4;

  @include media-breakpoint-down(m) {
    grid-row-start: 8;
    grid-row-end: 9;
  }

  @include media-breakpoint-down(s) {
    width: 298px;
    height: 78px;
  }
}
