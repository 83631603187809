@use "styles/mixins/breakpoints" as *;

.wrapper {
  max-width: 1217px;
  margin: 0 auto;

  @media (max-width: 1217px) {
    padding: 0 20px;
  }

  @include media-breakpoint-down(s) {
    padding: 0 16px;
  }
}
