@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 602px;
  padding: 40px;
  background: var(--white-palettes-100);
  color: var(--gray-palettes-100);

  @include media-breakpoint-down(s) {
    padding: 24px;
    width: 294px;
    height: 666px;
  }

  @include media-breakpoint-down(xs) {
    height: 756px;
  }
}

.title {
  display: block;
  margin-bottom: 24px;
}

.body {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}