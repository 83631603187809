@import "styles/variables";
@import "styles/mixins/breakpoints";

.root {
  position: relative;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows:
    auto
    1fr
    auto;
  align-items: self-start;
  width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 100vh;
}

.skipLink {
  &:focus-within {
    z-index: 10;

    display: block;
    width: unset;
    height: unset;
    padding: 1em;
    overflow: unset;

    white-space: unset;

    background-color: var(--text-secondary);

    clip: unset;
    clip-path: unset;
  }
}

.mainWrapper {
  border-top: 1px solid var(--gray-palettes-60);
  grid-area: content;
  padding: 40px 0;
  height: 100%;

  &WithoutHeader {
    padding: 0 0 40px 0;
    border-top: none;
  }

  @include media-breakpoint-down(s) {
    padding: 0;
  }
}

.header {
  grid-area: header;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px auto;
  color: var(--gray-palettes-100);

  @include media-breakpoint-down(s) {
    margin: 24px auto;
  }
}

.logo {
  cursor: pointer;
  transition: .2s;
  
  &:hover {
    opacity: .8;
  }

  &:active {
    opacity: 1;
  }
}

.footerWrapper {
  position: relative;
  grid-area: footer;
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  max-width: inherit;
}

.removeBlockEndPadding {
  padding-block-end: 0;
}
