@use "styles/mixins/breakpoints" as *;

.wrapperFooter {
  padding-top: 80px;
  padding-bottom: 80px;
  row-gap: 0;

  @include media-breakpoint-down(m) {
    padding-top: 40px;
    padding-bottom: 64px;
  }
}

.logoWrapper {
  grid-column-start: 1;
  grid-column: 1/6;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 24px;
  white-space: nowrap;

  @include media-breakpoint-down(m) {
    grid-template-columns: 1fr;
    grid-column: 1/4;
  }
}

.imgLogo {
  transition: 0.2s;
  width: 164px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.wrapperPhone {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media-breakpoint-down(xm) {
    gap: 4px;
  }
}

.phoneNumber {
  transition: 0.2s;
  color: var(--gray-palettes-100);
  white-space: nowrap;


  &:hover {
    color: var(--gray-palettes-90);
  }

  &:active {
    color: var(--blue-palettes-100);
  }
}

.descriptionPhone {
  --typography-caption1-font-size: 14px;
  --typography-caption1-line-height: 18px;

  color: var(--gray-palettes-90);
  white-space: nowrap;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapperSecurityLink {
  grid-column: auto / span 2;
  grid-column-start: 11;
  grid-row-start: 1;

  @include media-breakpoint-down(m) {
    grid-column: auto / span 4;
    grid-column-start: 1;
    grid-row-start: 4;

    margin-top: 40px;
  }
}

.descriptionCopyright {
  --typography-caption1-font-size: 14px;
  --typography-caption1-line-height: 18px;

  grid-column: auto / span 12;
  grid-column-start: 1;
  grid-row-start: 2;

  margin-top: 40px;

  color: var(--gray-palettes-90);

  @include media-breakpoint-down(m) {
    grid-column: auto / span 8;
    grid-column-start: 1;
    grid-row-start: 5;
  }

  @include media-breakpoint-down(s) {
    grid-column: auto / span 4;
    grid-column-start: 1;
    grid-row-start: 5;
  }
}

.link {
  transition: .2s;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &Current {
    color: var(--gray-palettes-90);

    &:hover {
      color: var(--gray-palettes-90);
    }
  }


}
