@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 40px;

  @include media-breakpoint-down(s) {
    padding-bottom: 24px;
  }
}

.accordionHeader {
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: var(--white-palettes-100);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
}

.accordionArrow {
  height: 24px;
  justify-self: end;

  @include media-breakpoint-down(s) {
    grid-row: 1;
  }

}

.accordionContentWrapper {
  padding: 0 16px 16px;
  background: var(--white-palettes-100);
}

.contentHead {
  margin-bottom: 18px;
}

.subtitle1 {
  color: var(--gray-palettes-100);

  @include media-breakpoint-down(s) {
    --typography-subtitle1-font-size: 16px;
    --typography-subtitle1-line-height: 24px;

    grid-row: 1;
  }
}

.bodyContent {
  color: var(--gray-palettes-90);

  --typography-body-m-font-size: 16px;
  --typography-body-m-line-height: 24px;
}

.accordion {
  box-shadow: var(--shadow-day);

  &:hover {
    box-shadow: var(--shadow-day-hover);
  }
}

.button {
  --button-font-size-medium: 16px;
  --button-font-weight: 500;
  --button-min-width-medium: 257px;
  --button-border-radius: 4px;

  margin-top: 24px;
}