@use "styles/mixins/breakpoints" as *;

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: var(--dark-blue-100, #091E38);
  padding: 80px 0;

  @include media-breakpoint-down(s) {
    padding: 40px 0;
  }
}

.title {
  display: block;
  color: var(--white-palettes-100);

  @include media-breakpoint-down(m) {
    text-align: center;
  }
}

.slider {
  padding-top: 24px;

  :global {
    .slick-slide {
      padding: 0 8px;
    }
  }
}