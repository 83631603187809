@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  list-style: none;
  margin: 0;
  padding: 8px 0;

  @include media-breakpoint-down(m) {
    display: none;
  }
}

.item {
  display: block;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &Black {
    color: var(--gray-palettes-100);
  }
}