@use "styles/mixins/breakpoints" as *;

.wrapper {
  row-gap: 74px;
  margin: 40px 0;

  @include media-breakpoint-down(s) {
    row-gap: 24px;
  }
}

.wrapperCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 190px;
  height: 190px;

  @include media-breakpoint-down(s) {
    width: 120px;
    height: 120px;
  }
}

.subtitle {
  display: flex;
  margin-top: 24px;
  text-align: center;

  @include media-breakpoint-down(s) {
    margin-top: 20px;

    --typography-subtitle1-font-size: 14px;
    --typography-subtitle1-line-height: 20px;
  }
}