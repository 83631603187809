@use "styles/mixins/breakpoints" as *;

.background {
  background-color: var(--gray-palettes-90);
}

.container {
  @include media-breakpoint-down(s) {
    padding: 0;
  }
}

.wrapperText {
  grid-column: auto/span 3;
  grid-column-start: 1 !important;
  grid-row-start: 1;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @include media-breakpoint-down(s) {
    grid-row-start: 2;
    grid-column-start: 1 !important;
    grid-column: auto/span 4;

    margin: 24px 24px 40px;
  }

  &Reverse {
    grid-column-start: 9 !important;
    grid-column: auto/span 4;
    margin-left: 46px;

    @include media-breakpoint-down(m) {
      grid-column-start: 6 !important;
      margin-left: 32px;
    }

    @include media-breakpoint-down(s) {
      grid-row-start: 2;
      grid-column-start: 1 !important;
      grid-column: auto/span 4;
      margin: 24px 24px 40px;
    }
  }
}

.firstHead {
  --typography-h2-font-size: 32px;

  @include media-breakpoint-down(s) {
    --typography-h2-font-size: 24px;
    --typography-h2-line-height: 32px;
  }
}

.secondHead {
  --typography-h2-font-size: 32px;
  color: var(--white-palettes-100);

  @include media-breakpoint-down(s) {
    --typography-h2-font-size: 24px;
    --typography-h2-line-height: 32px;
  }
}

.wrapperSlider {
  grid-column-start: 5 !important;
  grid-column: auto/span 8;
  grid-row-start: 1;

  :global {
    .slick-slide {
      height: 380px;

      @include media-breakpoint-down(s) {
        height: 200px;
      }
    }
  }

  @include media-breakpoint-down(m) {
    grid-column-start: 4 !important;
    grid-column: auto/span 5;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 1;
    grid-column-start: 1 !important;
    grid-column: auto/span 4;
  }

  &Reverse {
    grid-column-start: 1 !important;
  }
}

.slide {
  width: 897px;
  height: 380px;
  object-fit: cover;

  @include media-breakpoint-down(s) {
    width: 100%;
    height: 200px;
  }
}
